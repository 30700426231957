import React from 'react';

import { InputRow } from '../../../helpers/inputRow';
import { formatDateTimeLocal } from '../../../helpers/uiFunctions';

import { deleteWorkflowEvent, emailLead } from '../../data/leadsService';

class LeadWindow extends React.Component {
    
    send(id) {
		console.log("send Lead");
        var email = document.getElementById("sendLead-"+id);
		var eAdress = email.value;
		
        emailLead(id, eAdress, this.props.endpoints.serviceCrm, this.props.token).then(response => {
			console.log(response);
            // clear the input, some kind of success action
            if (response.success) {
                email.value = 'Email Sent....';
            } else {
                email.value = 'Email Failed....';
            }
            setTimeout(function() {
                email.value = '';
            }, 2000);
		});
	}
    delete(id) {
        deleteWorkflowEvent(id, this.props.endpoints.serviceCrm, this.props.token).then(response => {
            console.log(response);
            if(response.success) {
                document.getElementById("event-"+id).remove();
            }
        });
    }
    
    render() {
        console.log(this.props.lead);
        const lead = this.props.lead;
        const save = this.props.save;
        return(
            <div className={(lead.conversionStatus === null) ? ( "detail-window New" ) : ( "detail-window "+lead.conversionStatus )}>
                <div className="form">
                    <section>
                        <h3>{lead.name}</h3>
                        <p>Phone: {lead.phone}</p>
                        <p>Email: {lead.email}</p>
                        <p>Form: {lead.form}</p>
                        <p>Value: {lead.value}</p>
                    </section>
                    {(lead.comments) && (
                        <section>
                            <h5>Customer Message</h5>
                            <p><em>{lead.comments}</em></p>
                        </section>
                    )}
                    <section>
                        <h5>Share Lead</h5>
                        <InputRow label="Share To Email" id={"sendLead-"+lead.id} type="text" property="recipientEmail" />
                        <div className={"detail-actions"}>
                            <button className="white" onClick={() => this.send(lead.id)}>Share</button>
                        </div>
                    </section>
                    <section>
                        <h5>Manage Lead</h5>
                        <InputRow
                            label={"Lead Status"}
                            type="select"
                            property="conversionStatus"
                            id={"status-" + lead.id}
                            options={[
                                {"name": "Qualified","value": "Qualified"},
                                {"name": "Disqualified","value": "Disqualified"},
                                {"name": "Opportunity","value": "Opportunity"},
                                {"name": "Won","value": "Won"},
                                {"name": "Lost","value": "Lost"}
                            ]}
                        />
                        <InputRow 
                            label={"Add Note"}
                            type="textarea"
                            placeholder="Status Notes"
                            property="conversionDetail"
                            id={"detail-" + lead.id}
                        />
                        <InputRow
                            label={"Add Lead Value"}
                            type="number"
                            placeholder="Lead Value"
                            property="value"
                            step="0.01"
                            id={"value-" + lead.id}
                        />
                        <div className={"detail-actions"}>
                            <button className={"white"} onClick={() => save(lead.id)}>Add Status</button>
                        </div>
                    </section>
                    <h5>Lead History</h5>
                    <div className="table-heading">
                        <div>Date</div>
                        <div>Status</div>
                        <div>Notes</div>
                        <div>Value</div>
                        <div></div>
                    </div>
                    <ul className="detail-history">
                        {(lead.workflow !== null) && (
                            lead.workflow.sort((a, b) => (a["created"] < b["created"]) ? 1 : -1).map(event => 
                                <li key={event.id} id={"event-"+event.id}>
                                    <div>{formatDateTimeLocal(event.created)[0]}<br/>{formatDateTimeLocal(event.created)[1]}</div>
                                    <div>{event.status}</div>
                                    <div>{event.detail}</div>
                                    <div>{event.value}</div>
                                    <div className="trash"><button onClick={() => this.delete(event.id)} className="white"><i className="fas fa-trash"></i></button></div>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            </div>
        )
    }
}
export default LeadWindow;
import React from 'react';
import { numberToLocal, numberToPercent, numberToPrice } from '../../helpers/uiFunctions';
import { Statistic, ReportHeading } from '../../helpers/uiComponents';

import { InputRow } from '../../helpers/inputRow';
import { loadAdwords, loadAdwordsChannelType } from '../data/supermetricsService';

class Adwords extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			quickStats: {},
			adwordByChannelLabels: [],
			adwordByChannelValues: [],
			formFills: [],
			phoneCalls: [],
			localActions: [],
			sortVal: 0,   //no sorting
			rendered: false,
			message: null,
        };
        this.loadRetailer = this.loadRetailer.bind(this);
    }
	componentDidMount() {
        var qstats = {};
        var campaigns = {};
		// Adword by Channeltype
		loadAdwordsChannelType( //channel api call 1
            ["AdvertisingChannelType",
            "Cost",
            "Impressions", 
            "Clicks", 
            "Conversions", 
            "CostPerConversion"],
            null,
            ["Conversions desc"],
            10,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                let responseData = response.result.data;
				let dataLabels = responseData.shift();
				let additionalLabels = ['Form Fills', 'Phone Calls', 'Local Actions'];
				dataLabels.splice(4, 0, ...additionalLabels);
				let values = responseData;
				let updatedValues = values.map((item, index) => {
					let modifiedItem = item;
					modifiedItem.splice(4,0, ...[0, 0, 0]);
					return modifiedItem;
				});
				this.setState({
					adwordByChannelLabels : dataLabels,
					adwordByChannelValues: updatedValues
				  });

				  
            } else {
				console.log("not run", response);
			}
		});
		
		loadAdwordsChannelType( //channel api call 2
            ["AdvertisingChannelType",
            "Conversions", 
            ],
            "ConversionTypeName !~ .*[Pp]hone.*|.*[Cc]all.*|.*[Ll]ocal.*",
            ["Conversions desc"],
            10,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                let responseData = response.result.data;
				// console.log('Form Fills', responseData);
				let values = responseData;
				values.shift();
				this.setState({
					formFills: values
				  });
			} else {
				console.log("not run", response);
			}
		});
		loadAdwordsChannelType( //channel api call 3
            ["AdvertisingChannelType",
            "Conversions", 
            ],
            "ConversionTypeName =~ .*[Pp]hone.*|.*[Cc]all.* AND ConversionTypeName !~ .*[Ll]ocal.*",
            ["Conversions desc"],
            10,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                let responseData = response.result.data;
				// console.log('Phone Call', responseData);
				let values = responseData;
				values.shift();
				this.setState({
					phoneCalls: values
				  });

				  
            } else {
				console.log("not run", response);
			}
		});
		loadAdwordsChannelType( //channel api call 4
            ["AdvertisingChannelType",
            "Conversions", 
            ],
            "ConversionTypeName =~ .*[Ll]ocal.*",
            ["Conversions desc"],
            10,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                let responseData = response.result.data;
				// console.log('Local', responseData);
				let values = responseData;
				values.shift();
				this.setState({
					localActions: values
				  });

				  
            } else {
				console.log("not run", response);
			}
		});
		//  Adword by channeltype
        loadAdwords( //quick stats call 1
            ["Cost","Impressions","Clicks","Conversions","CostPerConversion",],
            "Campaignstatus != removed",
            null,
            10,
            true,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                var keys = response.result.data[0];
                var values = response.result.data[1];
                keys.forEach((key, i) => qstats[key] = values[i]);
                this.setState({ quickStats: qstats });
            }
		});
        loadAdwords( //quick stats call 2 - phone
            ["Conversions"],
            "Campaignstatus != removed AND ConversionTypeName =~   .*Call.*|.*call.*|.*Phone.*|.*phone.*",
            ["Conversions desc"],
            10,
            true,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                qstats["Calls"] = response.result.data[1][0];
                qstats["Change % Calls"] = response.result.data[1][1];
                this.setState({ quickStats: qstats });
            }
        });
        loadAdwords( //quick stats call 3 - form fills
            ["Conversions"],
            "Campaignstatus != removed AND ConversionTypeName !~   .*Call.*|.*call.*|.*Phone.*|.*phone.*|.*Local.*",
            ["Conversions desc"],
            10,
            true,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                qstats["Forms"] = response.result.data[1][0];
                qstats["Change % Forms"] = response.result.data[1][1];
                this.setState({ quickStats: qstats });
            }
        });

		loadAdwords( //quick stats call 4 - Local Actions
            ["Conversions"],
            "Campaignstatus != removed AND ConversionTypeName =~   .*Local.*",
            ["Conversions desc"],
            10,
            true,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success && response.result.data.length > 0) {
                qstats["Local Actions"] = response.result.data[1][0];
                qstats["Local % Actions"] = response.result.data[1][1];
                this.setState({ quickStats: qstats });
            }
        });

        loadAdwords(
            ["Campaignname","Cost","Impressions","Clicks","Ctr","CPC","Conversions","CostPerConversion"],
            "Campaignstatus != removed",
            ["Conversions desc"],
            100,
            false,
            this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
        ).then (response => {
            if (response.success && response.result.data.length > 0) {
                var data = response.result.data;
				var labels = {};
                labels = data.shift();
                labels.splice(7, 0, "PhoneCalls")
                labels.splice(8, 0, "Form Fills")
                campaigns = data;
                for (var i in campaigns) {
                    campaigns[i].splice(7, 0, 0)
                    campaigns[i].splice(8, 0, 0)
                }
                this.setState({
					labels: labels,
					campaigns: campaigns
				});
            }
        }).then(() => {
            loadAdwords(
                ["Campaignname","Conversions"],
                "Campaignstatus != removed AND ConversionTypeName =~   .*Call.*|.*call.*|.*Phone.*|.*phone.*",
                ["Conversions desc"],
                100,
                false,
                this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
            ).then (response => {
                if (response.success && response.result.data.length > 0) {
                    var data = response.result.data;
                    data.shift();
                    for (let i = 0; i < campaigns.length; i++) {
                        var campName = campaigns[i][0];
                        for (let j = 0; j < data.length; j++) {
                            var dataName = data[j][0];
                            if (dataName === campName) {
                                campaigns[i][7] = data[j][1];
                            }
                        }
                    }
                    this.setState({
						campaigns: campaigns
					});
                }
            });
            loadAdwords(
                ["Campaignname","Conversions"],
                "Campaignstatus != removed AND ConversionTypeName !~   .*Call.*|.*call.*|.*Phone.*|.*phone.*|.*Local.*",
                ["Conversions desc"],
                100,
                false,
                this.props.endpoints, this.props.dates, this.props.currentClient.clientCode, localStorage.getItem('accessToken')
            ).then (response => {
                if (response.success && response.result.data.length > 0) {
                    var data = response.result.data;
                    data.shift();
                    for (let i = 0; i < campaigns.length; i++) {
                        var campName = campaigns[i][0];
                        for (let j = 0; j < data.length; j++) {
                            var dataName = data[j][0];
                            if (dataName === campName) {
                                campaigns[i][8] = data[j][1];
                            }
                        }
                    }
                    this.setState({
						campaigns: campaigns
					});
                }
            });
        });
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	if(this.state.adwordByChannelValues.length > 0 
	// 		&& (this.state.formFills.length > 0 
	// 		|| this.state.phoneCalls.length > 0 
	// 		|| this.state.localActions.length > 0)) {
			
	// 		let loadPrevValuesToUpdate = [...this.state.adwordByChannelValues];
	// 		let combinedArray = [[...this.state.formFills], [...this.state.phoneCalls], [...this.state.localActions]];
	// 		for(let i=0; i<combinedArray.length; i++) {
	// 			if(combinedArray[i].length == 0) { continue; }
	// 			let indexToAdd = i + 4;;
	// 			let currentArrayItem = combinedArray[i];
	// 			for(let j=0; j<currentArrayItem.length; j++) {
	// 				let currentItem = currentArrayItem[j];
	// 				for(let k=0; k<loadPrevValuesToUpdate.length; k++) {
	// 					if(loadPrevValuesToUpdate[k][0] === currentItem[0]) {
	// 						loadPrevValuesToUpdate[k][indexToAdd] = currentItem[1];
	// 					}
	// 				}
	// 			}
	// 		}

	// 		if(prevState.adwordByChannelValues !== this.state.adwordByChannelValues) {
	// 			console.log("Componenet did update called");
	// 			this.setState({
	// 				adwordByChannelValues: loadPrevValuesToUpdate
	// 			});
	// 		}
			

	// 	}
	// }
	componentDidUpdate(prevProps, prevState) {
		const { adwordByChannelValues, formFills, phoneCalls, localActions } = this.state;
		console.log("Componenet Did Update called");
		if (adwordByChannelValues.length > 0 
			&& (formFills.length > 0 || phoneCalls.length > 0 || localActions.length > 0)) {
	
			let loadPrevValuesToUpdate = [...adwordByChannelValues];
			let combinedArray = [formFills, phoneCalls, localActions];
			let updated = false;
	
			for (let i = 0; i < combinedArray.length; i++) {
				if (combinedArray[i].length === 0) continue;
				let indexToAdd = i + 4;
				let currentArrayItem = combinedArray[i];
				for (let j = 0; j < currentArrayItem.length; j++) {
					let currentItem = currentArrayItem[j];
					for (let k = 0; k < loadPrevValuesToUpdate.length; k++) {
						if (loadPrevValuesToUpdate[k][0] === currentItem[0] 
							&& loadPrevValuesToUpdate[k][indexToAdd] !== currentItem[1]) {
							loadPrevValuesToUpdate[k][indexToAdd] = currentItem[1];
							updated = true;
						}
					}
				}
			}
	
			// Only set state if there's an actual update
			if (updated) {
				this.setState({ adwordByChannelValues: loadPrevValuesToUpdate });
			}
		}
	}
	bindChaneelExtraValues(prevValues, extraValues, indexToAdd) {
		for(let i=0; i<extraValues.length; i++) {
			let currentItem = extraValues[i];
			for(let j=0; j<prevValues.length; j++) {
				if(prevValues[i][0] === currentItem[0]) {
					prevValues[i][indexToAdd] = currentItem[1];
				}
			}
		}

		return prevValues;
	}
    loadRetailer(e) {
		var affiliateCode = e.target.value;
		var currentData = this.props.data[1][1];
		currentData = currentData.filter (ad => {return ad.affiliateCode === affiliateCode });
		this.setState({
			campaigns: currentData,
		});
	}
	clearRetailer() {
		this.setState({
			campaigns: this.props.data[1][1],
		});
		document.getElementById("adword-retailer-list").selectedIndex = 0;
	}
	sortBy(i) {
		var list = this.state.campaigns;
		var sort = this.state.sortVal;
		if (sort === 0 || sort === 2) {
			list = list.sort((a, b) => (a[i] > b[i]) ? 1 : -1);
			sort = 1;
		} else {
			list = list.sort((a, b) => (a[i] < b[i]) ? 1 : -1);
			sort = 2;
		}
		this.setState({
			campaigns: list,
			sortVal: sort,  // up or down
		});
	}

	
	render() {
		const {quickStats, labels, campaigns, message } = this.state;
		const hideHistorical = this.props.hideHistorical;

		const isRetailer = this.props.isRetailer;
		const retailerList = this.props.retailerList;
		const roles = this.props.roles;
		// const affiliateCode = this.props.affiliateCode;

		//console.log(ads);

		return(
			(this.props.currentClient) && (
				<div id="metrics-adwords" className="report metrics-adwords">
					<ReportHeading
						title="Google Adwords Statistics"
						name={this.props.currentClient.name}
						dates={this.props.dates}
						hideHistorical={hideHistorical}
					/>
					{message && (
						<React.Fragment>
							<h3>We were unable to fetch your Adwords Statistics.</h3>
							<p>There may have been an error connecting to Adwords, or Adwords is not configured for your Portal.</p>
							<p>Please contact your Account Manager for assistance.</p>
						</React.Fragment>
					)}
					{( (isRetailer === false) && (retailerList) && (roles.includes("portalAdm") || roles.includes("portalVlctyMngr")) ) && (
        				<div className="retailer-selector">
	            			Retailer:
	            			<span className="clear-client"><i className="far fa-window-close" onClick={() => this.clearRetailer()}></i></span>
	            			<InputRow type="select" id="adword-retailer-list" property="retailerList" options={retailerList} onChange={this.loadRetailer} />
	            		</div>
            		)}
					{(!message) && (
							<React.Fragment>
								<section className="stats row">
									<Statistic
										label="Spend"
										value={(quickStats && quickStats.Cost) ? (numberToPrice(quickStats['Cost'])) : ("calculating..")}
										status={(quickStats && (quickStats['Change % Cost'] < 0)) ? ("down") : ("up")}
										percent={(quickStats) ? (quickStats['Change % Cost']) : (0)}
										hideHistorical={hideHistorical}
									/>
									<Statistic
										label="Impressions"
										value={(quickStats && quickStats.Impressions) ? (numberToLocal(quickStats['Impressions'])) : ("calculating..")}
										status={(quickStats && (quickStats['Change % Impressions'] < 0)) ? ("down") : ("up")}
										percent={(quickStats) ? (quickStats['Change % Impressions']) : (0)}
										hideHistorical={hideHistorical}
									/>
									<Statistic
										label="Clicks"
										value={(quickStats && quickStats.Clicks) ? (numberToLocal(quickStats['Clicks'])) : ("calculating..")}
										status={(quickStats && (quickStats['Change % Clicks'] < 0)) ? ("down") : ("up")}
										percent={(quickStats) ? (quickStats['Change % Clicks']) : (0)}
										hideHistorical={hideHistorical}
									/>
									<Statistic
										label="Conversions"
										value={(quickStats && quickStats.Conversions) ? (numberToLocal(quickStats['Conversions'])) : ("calculating..")}
										status={(quickStats && (quickStats['Change % Conversions'] < 0)) ? ("down") : ("up")}
										percent={(quickStats) ? (quickStats['Change % Conversions']) : (0)}
										hideHistorical={hideHistorical}
									/>
									</section>
									<section className="stats row">
									<Statistic
										label="Phone Calls"
										value={(quickStats && quickStats.Calls) ? (numberToLocal(quickStats['Calls'])) : ("calculating..")}
										status={(quickStats && (quickStats['Change % Calls'] < 0)) ? ("down") : ("up")}
										percent={(quickStats) ? (quickStats['Change % Calls']) : (0)}
										hideHistorical={hideHistorical}
									/>
									<Statistic
										label="Form Fills"
										value={(quickStats && quickStats.Forms) ? (numberToLocal(quickStats['Forms'])) : ("calculating..")}
										status={(quickStats && (quickStats['Change % Forms'] < 0)) ? ("down") : ("up")}
										percent={(quickStats) ? (quickStats['Change % Forms']) : (0)}
										hideHistorical={hideHistorical}
									/>
									<Statistic
										label="Local Actions"
										value={(quickStats && quickStats.Forms) ? (numberToLocal(quickStats['Local Actions'])) : ("calculating..")}
										status={(quickStats && (quickStats['Local % Actions'] < 0)) ? ("down") : ("up")}
										percent={(quickStats) ? (quickStats['Local % Actions']) : (0)}
										hideHistorical={hideHistorical}
									/>
									<Statistic
										label="Cost Per Conversion"
										value={(quickStats && quickStats['Cost per conversion']) ? (numberToPrice(quickStats['Cost per conversion'])) : ("calculating..")}
										status={(quickStats && (quickStats['Change % Cost per conversion'] < 0)) ? ("down") : ("up")}
										percent={(quickStats) ? (quickStats['Change % Cost per conversion']) : (0)}
										hideHistorical={hideHistorical}
									/>
								</section>

								<section className="calls-list">
									<div class="table_wrapper_scroll">
										<div class="table_wrapper_inner">
											<div className="table-heading">
												

												{this.state.adwordByChannelLabels &&
												this.state.adwordByChannelLabels.map((label,index) =>
													<div key={index} className="sortable">{label}</div>
												)}
											</div>
											<ul className="table-body no-hover">
												{this.state.adwordByChannelValues &&
												this.state.adwordByChannelValues.map((ad, index) => 
													<li key={index}>
														{ad.map((dataPoint, i) =>
															<div key={i}>
																{([0].indexOf(i) !== -1) 		&& dataPoint}
																{([1].indexOf(i) !== -1) 	&& numberToPrice(dataPoint)}
																{([2].indexOf(i) !== -1) 	&& numberToLocal(dataPoint)}
																{([3].indexOf(i) !== -1) 		&& numberToLocal(dataPoint)}
																{([4].indexOf(i) !== -1) 		&& dataPoint}
																{([5].indexOf(i) !== -1) 		&& dataPoint}
																{([6].indexOf(i) !== -1) 		&& dataPoint}
																{([7].indexOf(i) !== -1) 		&& dataPoint}
																{([8].indexOf(i) !== -1) 		&& numberToPrice(dataPoint)}
															</div>
														)}
													</li>
												)}
											</ul>
										</div>
									</div>
								</section>

								<section className="calls-list">
									<div class="table_wrapper_scroll">
										<div class="table_wrapper_inner">
											<div className="table-heading">
												{labels &&
												labels.map((label,index) =>
													<div key={index} className="sortable" onClick={() => this.sortBy(index)}>{label} <span><i className="fas fa-sort"></i></span></div>
												)}
											</div>
											<ul className="table-body no-hover">
												{campaigns &&
												campaigns.map((ad, index) => 
													<li key={index}>
														{ad.map((dataPoint, i) =>
															<div key={i}>
																{([0].indexOf(i) !== -1) 		&& dataPoint}
																{([1,5,9].indexOf(i) !== -1) 	&& numberToPrice(dataPoint)}
																{([2,3,6,7,8].indexOf(i) !== -1) 	&& numberToLocal(dataPoint)}
																{([4].indexOf(i) !== -1) 		&& numberToPercent(dataPoint)}
															</div>
														)}
													</li>
												)}
											</ul>
										</div>
									</div>
								</section>

							</React.Fragment>
					)}
				</div>
			)
		)
	}

}

export default Adwords;
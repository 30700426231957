import React from 'react';

import { formatDate, numberToLocal, numberToPrice } from '../../helpers/uiFunctions';
import { Statistic, ReportHeading, Invoice } from '../../helpers/uiComponents';

import { printStyles } from '../../css/components/printInvoice.js';

class RugsShopOrders extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			orders: [],
			count: 0,
			totalSales: 0,
			totalDiscount: 0,
			invoiceId: 0,

			sortVal: 0,
			currentPage: 1,
			leadsPerPage: 25,

			loading: true,
			errorMessage: null,
        };
        this.changePage = this.changePage.bind(this);
        this.changeLeadCount = this.changeLeadCount.bind(this);
        this.viewInvoice = this.viewInvoice.bind(this);
        this.closeInvoice = this.closeInvoice.bind(this);
    }
	componentDidMount() {
		try {
			if (this.props.orders) {
				this.handleData(this.props.orders);
			}
		} catch (error) {
			console.log(error)
		}
	}
    componentDidUpdate(prevProps) {
		try {
			// console.log(this.props.orders);
			if (this.props.orders !== null && (this.props.orders !== prevProps.orders)) {
				this.handleData(this.props.orders);
			}
		} catch (error) {
			console.log(error);
		}
	}
	handleData(data) {
		if (!data.message) {
			var total_sales = 0;
			var total_discount = 0;
			var total_commission = 0;
			for (var i = data.items.length - 1; i >= 0; i--) {
				if (data.items[i].total_invoiced) {
					total_sales = total_sales + data.items[i].total_invoiced;
				}
				total_discount = total_discount + data.items[i].discount_amount;
				total_commission = total_commission + this.getCommissionEarned(data.items[i].subtotal, data.items[i].discount_amount, data.items[i].total_refunded, data.items[i].status)
			}
			//console.log(total_sales, total_discount)
			this.setState({
				orders: data.items,
				count: data.total_count,
				totalSales: total_sales,
				totalDiscount: total_discount,
				totalCommission: total_commission,
				loading: false,
				errorMessage: null,
			});
		} else {
			this.setState({
				loading: false,
				errorMessage: data.message,
			})
		}
	}
	sortBy(prop) {
		var list = this.state.orders;
		var sort = this.state.sortVal;
		if (sort === 0 || sort === 2) {
			list = list.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
			sort = 1;
		} else {
			list = list.sort((a, b) => (a[prop] < b[prop]) ? 1 : -1);
			sort = 2;
		}
		this.setState({
			orders: list,
			sortVal: sort,  // up or down
		});
	}
	changePage(e) {
		this.setState({
			currentPage: Number(e.target.id),
		});
	}
	changeLeadCount(e) {
		this.setState({
			leadsPerPage: e.target.options[e.target.selectedIndex].value,
			currentPage: 1,
		});
	}

	getCommissionSaleTotal(subtotal, discount, refund) {
		if (!refund) {
			refund = 0
		}

		var total = subtotal + discount - refund;

		if (total <= 0) {
			total = 0
		}

		return (numberToPrice(total));
	}
	getCommissionEarned(subtotal, discount, refund, status) {
		if (!refund) {
			refund = 0
		}

		var total = subtotal + discount - refund;

		if (total <= 0 || status === "closed" || status === "canceled") {
			total = 0
		} else {
			total = total * 0.2
		}

		return (total);
	}
	viewInvoice(id) {
		this.setState({invoiceId: id});
	}
	closeInvoice() {
		this.setState({invoiceId: 0});
	}
	printInvoice() {
		// console.log("will print invoice");
		var data=document.querySelector(".invoice_container").innerHTML;
        var myWindow = window.open('', 'print invoice', 'height=400,width=600');
        myWindow.document.write('<html><head><title>Print Invoice</title>');
        myWindow.document.write(printStyles);
        myWindow.document.write('</head><body >');
        myWindow.document.write(data);
        myWindow.document.write('</body></html>');
        myWindow.document.close(); // necessary for IE >= 10
        myWindow.onload=function(){ // necessary if the div contain images
            myWindow.focus(); // necessary for IE >= 10
            myWindow.print();
            myWindow.close();
        };
	}

	render() {
		const {orders, count, totalSales, totalDiscount, totalCommission, invoiceId, currentPage, leadsPerPage, loading, errorMessage } = this.state;

		const indexOfLastTodo = currentPage * leadsPerPage;
		const indexOfFirstTodo = indexOfLastTodo - leadsPerPage;
		const currentLeads = orders.slice(indexOfFirstTodo, indexOfLastTodo);

		const renderLeads = currentLeads.map((order, index) => {
			return (
				<React.Fragment key={order.increment_id}>
					<li onClick={() => this.viewInvoice(order.increment_id)}>
						<div><span className="play">{order.increment_id}</span></div>
						<div>{formatDate(order.created_at)[0]}</div>
						<div>{order.status}</div>
						
						<div>{numberToPrice(order.subtotal)}</div>
						<div>{numberToPrice(order.discount_amount)}</div>
						<div>{numberToPrice(order.total_refunded)}</div>
						<div>{numberToPrice(order.shipping_invoiced)}</div>
						<div>{numberToPrice(order.total_invoiced)}</div>
						<div>{this.getCommissionSaleTotal(order.subtotal, order.discount_amount, order.total_refunded)}</div>
						<div>{numberToPrice(this.getCommissionEarned(order.subtotal, order.discount_amount, order.total_refunded, order.status))}</div>
					</li>
					{(invoiceId === order.increment_id) && (
						<Invoice
							order={order}
							currentClient={this.props.currentClient}
							closeInvoice={this.closeInvoice}
							printInvoice={this.printInvoice}
						 />
					)}
				</React.Fragment>
			);
		});

		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(orders.length / leadsPerPage); i++) {
			pageNumbers.push(i);
		}

		const renderPageNumbers = pageNumbers.map(number => {
			return (
				(number === this.state.currentPage) ? (
					<li key={number} id={number} onClick={this.changePage} className="active" >
						{number}
					</li>
				) : (
					<li key={number} id={number} onClick={this.changePage} >
						{number}
					</li>
				)
			);
		});

		return(
			(this.props.currentClient) && (
				<div id="metrics-rugsShop" className="report metrics-rugsShop">
					<ReportHeading
						title="Rugs Shop Orders"
						name={this.props.currentClient.name}
						dates={this.props.dates}
						hideHistorical={true}
					/>
					{errorMessage ? ( <p>{errorMessage}</p> ) : (
						<React.Fragment>
							<section className="stats row">
								<Statistic
									label="Total Orders"
									value={numberToLocal(count)}
									hideHistorical={true}
								/>
								<Statistic
									label="Total Invoiced"
									value={numberToPrice(totalSales)}
									hideHistorical={true}
								/>
								<Statistic
									label="Total Discount"
									value={numberToPrice(totalDiscount)}
									hideHistorical={true}
								/>
								<Statistic
									label="Total Commission"
									value={numberToPrice(totalCommission)}
									hideHistorical={true}
								/>
							</section>

							{(loading === true) && (
		            			<div className="report-loading">
		            				<p><i className="fas fa-circle-notch"></i></p>
		            			</div>
		            		)}

							<section className="calls-list">
								{/* <div className="pagination">
									<ul className="page-numbers">
										{renderPageNumbers}
									</ul>
									<InputRow 
										label="Results Per Page" type="select" property="leadsPerPage" id="resultsPerPage" value={leadsPerPage} onChange={this.changeLeadCount}
										options={[
											{ "name": "25", "value": "25" },
											{ "name": "50", "value": "50" },
											{ "name": "100", "value": "100" },
											{ "name": "200", "value": "200" }
										]}
									/>
								</div> */}
								<div className='table_wrapper_inner'>
								<div className="table-heading">
									<div className="sortable" onClick={() => this.sortBy("increment_id")}>Order ID / Invoice <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("created_at")}>Date  <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("status")}>Status  <span><i className="fas fa-sort"></i></span></div>
									
									<div className="sortable" onClick={() => this.sortBy("subtotal")}>Cart Subtotal  <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("discount_amount")}>Discount  <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("total_refunded")}>Refund  <span><i className="fas fa-sort"></i></span></div>
									
									<div className="sortable" onClick={() => this.sortBy("shipping_invoiced")}>Shipping  <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("total_invoiced")}>Invoice Total  <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("subtotal")}>Commission Sale Value  <span><i className="fas fa-sort"></i></span></div>
									<div>Commission Earned</div>
								</div>
								<ul className="table-body">
									{renderLeads}
								</ul>
								</div>
								<div className="pagination">
									<ul className="page-numbers">
										{renderPageNumbers}
									</ul>
									{/* <InputRow 
										label="Results Per Page" type="select" property="leadsPerPage" id="resultsPerPage" value={leadsPerPage} onChange={this.changeLeadCount}
										options={[
											{ "name": "25", "value": "25" },
											{ "name": "50", "value": "50" },
											{ "name": "100", "value": "100" },
											{ "name": "200", "value": "200" }
										]}
									/> */}
								</div>
							</section>
						</React.Fragment>
					)}
				</div>
			)
		)
	}

}
export default RugsShopOrders;